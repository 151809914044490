body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(1, 7, 7, 0.103);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

td, th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  width: 10px;
  height: 25px;
  border-color: black;
  z-index: 2;
}

table{
  margin: auto;
}
td:first-child {
  border-left:solid;
}
td:nth-child(3n) {
  border-right:solid ;
}
tr:first-child {
  border-top:solid;
}
tr:nth-child(3n) td {
  border-bottom:solid ;
}